import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { TrackerType, UserPatchType } from '@meniudigital/shared'
import Setting from './Setting'
import { patchCurrentUser } from 'src/state/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'

const allTrackingServices = [
  {
    type: TrackerType.GoogleAnalytics,
    name: 'Google Analytics',
    placeholder: `ID-ul proiectului tău (e.g. G-YMJ36VFP3W)`,
  },
  {
    type: TrackerType.MetaPixel,
    name: 'Meta (Facebook) Pixel',
    placeholder: `ID-ul proiectului tău (e.g. 123456789012345)`,
  },
]

export default function TrackingSetting() {
  const dispatch = useDispatch()

  const { user } = useSelector((state: State) => state.users)

  const isTrackerActive = (type: TrackerType) => {
    return (user?.tracking || []).some(x => x.type === type && x.isActive === true)
  }

  const toggleTrackerVisibility = (type: TrackerType) => {
    const currentSetting = (user?.tracking || []).find(x => x.type === type)!
    dispatch(
      patchCurrentUser({ type: UserPatchType.Tracking, data: { ...currentSetting, type, isActive: !currentSetting?.isActive } })
    )
  }

  const updateTrackerId = (newId: string, type: TrackerType) => {
    const currentSetting = (user?.tracking || []).find(x => x.type === type)!

    dispatch(patchCurrentUser({ type: UserPatchType.Tracking, data: { ...currentSetting, type, id: newId } }))
  }

  if (!user) {
    return null
  }

  return (
    <>
      <div className="setting-explainer">Dorești să adaugi analytics/tracking pe meniul tău?</div>

      <div className="social-media">
        {allTrackingServices.map(({ type, name, placeholder }) => (
          <Setting
            key={type}
            title={name}
            iconPath={`/logos/${type}.svg`}
            isVisible={isTrackerActive(type)}
            value={(user?.tracking || []).find(x => x.type === type)?.id}
            placeholder={placeholder}
            onValueUpdate={newValue => updateTrackerId(newValue, type)}
            onVisibilityToggle={() => toggleTrackerVisibility(type)}
          />
        ))}
      </div>
      <div className="setting-explainer warning" style={{ marginTop: 16, marginBottom: 40 }}>
        <FontAwesomeIcon icon={faWarning} />{' '}
        {(user?.tracking || []).some(x => x.isActive) ? ' C' : ' Prin activarea unui tracker, c'}lienții tăi vor trebui să accepte
        un pop-up de consimțământ GDPR înaintea accesării meniului.
      </div>
    </>
  )
}
