import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 12vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 600px;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};
  height: min(calc(100vh - 190px), 768px);

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  button[data-variant='as-text'] {
    margin-top: 10px;
    padding-left: 0;
    height: auto;
  }

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  .product-image-wrapper {
    height: 55px;
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;

    .product-image-wrapper {
      height: 35px;
    }
  }

  .map-container {
    height: 170px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    cursor: default;
    pointer-events: none;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);

    .address-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 8px;
      background: ${props => props.theme.white};
      border-bottom-right-radius: 8px;
      border-top-left-radius: 8px;
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      font-size: 14px;
    }

    .client-marker-wrapper {
      padding-right: 8px;
      pointer-events: none;

      .client-marker {
        background: ${props => props.theme.white};
        border-radius: 24px;
        border-top-left-radius: 0;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

        overflow: hidden;
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 3px solid ${props => props.theme.blue};

        .client-logo {
          height: 100%;
        }

        .client-name {
          height: 100%;
          padding-left: 8px;
          padding-right: 16px;
          display: flex;
          align-items: center;
          /* border-bottom: 2px solid red; */

          font-size: 14px;
          font-weight: 500;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  background: ${props => props.theme.pageBackground};
`

export const ModalContent = styled.div`
  position: relative;
  overflow: auto;
  height: calc(100% - 52px);
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
  }

  .subtitle {
    color: ${props => props.theme.mutedText};
    margin: 8px 0;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;

    .fa {
      margin-right: 6px;
    }
  }

  .left-side {
    flex: 1;
  }

  .setting {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0px 12px ${props => props.theme.mutedText}44;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border: 1px solid ${props => props.theme.white};
    transition: all 0.2s cubic-bezier(0.25, 1, 0.32, 1);
    background: ${props => props.theme.white};
    box-shadow: none;
    /* padding: 16px 8px; */
    border: 1px solid ${props => props.theme.border};
    width: 100%;
    position: relative;

    .day-pills {
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      margin: 16px 0;
      min-height: auto;
    }

    .business-tag {
      background: linear-gradient(20deg, hsl(208deg 18% 35%), hsl(208deg 18% 50%));
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      padding: 6px 8px;
      padding-left: 10px;
      border-top-left-radius: 12px;
      z-index: 10;
      transition: filter 0.2s ease-in;
      font-size: 14px;

      img {
        width: 14px;
      }

      .business-text {
        margin-left: 4px;
        font-weight: 500;
      }
    }

    input {
      padding-right: 12px;
    }

    &:nth-of-type(even) {
      background-color: ${props => props.theme.activeBackground};

      input {
        background-color: ${props => props.theme.white};
      }
    }

    &.grouped {
      border-radius: 0;
      border-top-width: 0;

      &:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-top-width: 1px;
      }

      &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &.disabled-in-plan {
      cursor: pointer;
      transition: filter 0.15s ease-in-out;

      &:hover {
        filter: brightness(0.93);
        .business-tag {
          filter: brightness(1.1);
        }
      }

      .left-side,
      .right-side {
        pointer-events: none;
      }
    }

    .setting-icon {
      width: 24px;
      height: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      font-size: 13px;
      color: #fff;

      img {
        width: 24px;
      }
    }

    .invalid-url {
      color: ${props => props.theme.red};
      font-size: 14px;
      font-weight: 500;
      margin-top: 4px;
    }

    .bullet {
      margin-left: 8px;
      margin-top: 4px;
      flex-shrink: 0;
    }

    .setting-name {
      font-weight: 500;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
    }
  }

  .setting-explainer {
    margin-bottom: 16px;
    margin-top: 40px;
    font-size: 14px;

    svg {
      color: ${props => props.theme.accent};
    }

    &.warning {
      svg {
        color: ${props => props.theme.orange};
      }
    }
  }

  .separator-wrapper {
    width: 100%;
    margin: 32px 0;

    .separator {
      width: 100%;
      border-top: 1px solid ${props => props.theme.pageBackground};
    }
  }

  .right-side {
    display: flex;
    align-items: center;

    .toggle {
      margin-left: 8px;

      &.react-toggle .react-toggle-thumb {
        height: 28px;
        width: 28px;
      }

      &.react-toggle--focus .react-toggle-thumb {
        box-shadow: none !important;
      }

      &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${props => props.theme.mutedText}44;
      }

      &.react-toggle .react-toggle-track {
        height: 30px;
        width: 56px;
        background-color: ${props => props.theme.mutedText}88;
      }

      &.react-toggle:not(.react-toggle--checked) .react-toggle-thumb {
        border-color: ${props => props.theme.mutedText}88;
      }

      &.react-toggle--checked .react-toggle-thumb {
        border-color: ${props => props.theme.accent};
      }

      &.react-toggle--checked .react-toggle-track {
        background-color: ${props => props.theme.accent};
      }

      &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: ${props => props.theme.accent}bb;
      }
    }
  }

  .setting-group {
    background-color: ${props => props.theme.accent}22;
    padding: 16px;
    border-radius: 8px;
    margin-top: 32px;

    &.danger {
      background-color: ${props => props.theme.red}44;

      i {
        color: ${props => props.theme.red};
      }
    }
  }

  .auto-save {
    text-align: center;
    font-size: 14px;
    margin-top: 24px;
    color: ${props => props.theme.mutedText};
    font-weight: 500;

    svg {
      margin-right: 4px;
      color: ${props => props.theme.green};
      font-size: 14px;
      transition: width 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    }
  }
`

export const ModalText = styled.div`
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
